import {mapMutations, mapActions, mapGetters} from "vuex";

//sections


export default {
    name: "manual",
    components: {},
    props: {},
    data() {
        return {
        }
    },
    mounted() {
        this.getPageContent()
        this.setRequestFlag(true);
    },
    computed: {
        ...mapGetters({
            manualPage: 'manuals/manualPage',
            globalRequestsFlag: 'system/globalRequestsFlag',
            manualsGroup: 'manuals/manualsGroup',
            typeManuals: 'manuals/manualType'
        })
    },

    methods: {
        ...mapActions({
            getPage: 'manuals/GET_MANUAL_PAGE_OR_GROUP',

        }),
        ...mapMutations({
            setRequestFlag: 'system/SET_REQUESTS_FLAG',
            setBannerContent: 'manuals/SET_BANNER_CONTENT',
        }),
        getPageContent() {
            let slug = this.$route.params.slug

            this.getPage(slug).then(() => {

                document.title = `${this.manualsGroup.title || this.manualPage.title}`

                let obj = {}
                obj.bannerImage = this.manualPage.bannerImage
                obj.description = this.manualPage.description
                obj.titleForBanner = this.manualPage.title

                this.setBannerContent(obj)
            })
        },

    }
}
